import React from "react";
import PropTypes from "prop-types";
import Header from "../../components/Header/Header";
import ContactUs from "../../components/ContactUs/ContactUs";
import Footer from "../../components/Footer/Footer";
import Masonry from "react-masonry-component";

import "./item.scss";
import background1_img from "../../assets/img/portfolio/bg_1.png";
import preview1_img from "../../assets/img/portfolio/img_1.png";
import background2_img from "../../assets/img/portfolio/bg_2.png";
import preview2_img from "../../assets/img/portfolio/img_2.png";
import restaurantPortfolioImg1
    from "../../assets/img/portfolio/restaurant-portfolio-1.png";
import restaurantPortfolioImg2
    from "../../assets/img/portfolio/restaurant-portfolio-2.png";
import restaurantPortfolioImg3
    from "../../assets/img/portfolio/restaurant-portfolio-3.png";
import background6_img from "../../assets/img/portfolio/bg_6.png";
import preview6_img from "../../assets/img/portfolio/img_6.png";
import background4_img from "../../assets/img/portfolio/bg_4.png";
import preview4_img from "../../assets/img/portfolio/img_4.png";
import background3_img from "../../assets/img/portfolio/bg_3.png";
import preview3_img from "../../assets/img/portfolio/img_3.png";
import background5_img from "../../assets/img/portfolio/bg_5.png";
import preview5_img from "../../assets/img/portfolio/img_5.png";
import Loader from "../../components/Loader/Loader.jsx";

PortfolioItem.propTypes = {};

const DATA = [
    {
        _id: "1",
        previewBackground: background1_img,
        previewImage: preview1_img,
        title: "Driving+",
        images: []
    },
    {
        _id: "2",
        previewBackground: background2_img,
        previewImage: preview2_img,
        title: "11 Mirrors Rooftop Restaurant & Bar",
        images: [
            restaurantPortfolioImg1,
            restaurantPortfolioImg2,
            restaurantPortfolioImg3
        ]
    },
    {
        _id: "3",
        previewBackground: background6_img,
        previewImage: preview6_img,
        title: "UPSTART NETWORK",
        images: []
    },
    {
        _id: "4",
        previewBackground: background4_img,
        previewImage: preview4_img,
        title: "QLP",
        images: []
    },
    {
        _id: "5",
        previewBackground: background3_img,
        previewImage: preview3_img,
        title: "Центр Ridni",
        images: []
    },
    {
        _id: "6",
        previewBackground: background5_img,
        previewImage: preview5_img,
        title: "hiTurkey",
        images: []
    },
];

function PortfolioItem( {params} ) {
    if ( !DATA || !params.id ) {
        return <Loader fixed/>;
    }

    if ( !DATA.length ) {
        return <p>There is no available information about this project</p>;
    }
    const {title, images} = DATA.find(project => project._id === params.id);

    const pictureList = images.map(( img, index ) => {
        return (
            <li
                key={ `${ Date.now() }-${ index }` }
                className="project-content__grid-item"
            >
                <figure className="project-content__grid-image">
                    <img src={ img } alt={ title }/>
                </figure>
            </li>
        );
    });

    const masonryOptions = {
        transitionDuration: 0.3,
        itemSelector: ".project-content__grid-item",
        columnWidth: ".project-content__grid-sizer",
        gutter: ".project-content__gutter-sizer",
        percentPosition: true
    };

    return (
        <>
            <title>{ title }</title>
            <Header/>
            <main className="project-content">
                <section>
                    <div className="project-content__inner wrapper">
                        <h1 className="project-content__heading page-heading">{ title }</h1>
                        <Masonry
                            className="project-content__grid"
                            elementType="ul"
                            options={ masonryOptions }
                        >
                            { pictureList }
                            <li className="project-content__grid-sizer"/>
                            <li className="project-content__gutter-sizer"/>
                        </Masonry>
                    </div>
                </section>
                <ContactUs/>
            </main>
            <Footer/>
        </>
    );
}

export default PortfolioItem;